$c-primary: #e30712;
$c-secandary: #000000;
$dots: 'background/vodafone-dots.png';
$progress-bar-overided: white;
$loading-bg-overrided: #e30712;
$progress-bar-incomplete-overided: #a2050b;
$base-font-family: 'Vodafone', Arial, Helvetica, sans-serif;
$font-family-heading: 'Vodafone', Arial, Helvetica, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: stretch;
    height: 100%;
  
    .description {
      text-align: left;
    }
  
    .animation-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      overflow: hidden;
      background-color: var(--page-background-color);
    }
  }
  