$c-primary: #e30712;
$c-secandary: #000000;
$dots: 'background/vodafone-dots.png';
$progress-bar-overided: white;
$loading-bg-overrided: #e30712;
$progress-bar-incomplete-overided: #a2050b;
$base-font-family: 'Vodafone', Arial, Helvetica, sans-serif;
$font-family-heading: 'Vodafone', Arial, Helvetica, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
