$c-primary: #e30712;
$c-secandary: #000000;
$dots: 'background/vodafone-dots.png';
$progress-bar-overided: white;
$loading-bg-overrided: #e30712;
$progress-bar-incomplete-overided: #a2050b;
$base-font-family: 'Vodafone', Arial, Helvetica, sans-serif;
$font-family-heading: 'Vodafone', Arial, Helvetica, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
}
