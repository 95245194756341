$c-primary: #e30712;
$c-secandary: #000000;
$dots: 'background/vodafone-dots.png';
$progress-bar-overided: white;
$loading-bg-overrided: #e30712;
$progress-bar-incomplete-overided: #a2050b;
$base-font-family: 'Vodafone', Arial, Helvetica, sans-serif;
$font-family-heading: 'Vodafone', Arial, Helvetica, sans-serif;

      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYBld.TTF');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYBld-Italic.TTF');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYRg.TTF');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYRg-Italic.TTF');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYLt-Regular.TTF');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Vodafone';
        src: url('/assets-mobile/fonts/VodafonePTYLt-Italic.TTF');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Maax';
        src: url('/assets-mobile/fonts/Maax-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Maax';
        src: url('/assets-mobile/fonts/Maax-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'PultoSans';
        src: url('/assets-mobile/fonts/PultoSans-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'PultoSans';
        src: url('/assets-mobile/fonts/PultoSans-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Figtree';
        src: url('/assets-mobile/fonts/Figtree-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BebasNeue';
        src: url('/assets-mobile/fonts/BebasNeue-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.mobile {
  @media only screen and (max-width: 320px) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 321px) {
    font-size: 16px !important;
  }

  @media only screen and (min-width: 480px) {
    font-size: 20px !important;
  }

  @media only screen and (min-width: 720px) {
    font-size: 24px !important;
  }

  @media only screen and (min-width: 960px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 32px !important;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 36px !important;
  }
}

.desktop {
  font-size: 18px !important;
}
